const ThankYou = () => {
  return (
    <div className="h-5/6 flex flex-col items-center justify-between text-center text-white ">
      <div className="text-white text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">Thank you for your time!</div>
      <p className="text-white">We will be in touch soon to share the next steps with you.</p>
    </div>
  );
};

export default ThankYou;

import React, { useState, useEffect, useMemo } from 'react';
import { RadialBarChart, RadialBar, PolarAngleAxis } from 'recharts';
import ChartButtonContainer from '../../components/ChartButtonContainer';
import '../../styles/Charts.css';
import { ComputedPersonality } from './utils';

const getPersonalityDescription = (
  activePersonality: {
    key: string;
    name: string;
    description: string[][];
    color: string;
    className: string;
  },
  personalityValue: number,
) => {
  let text: string[] | string[][];
  if (personalityValue > 50) {
    text = activePersonality.description;
  } else if (personalityValue === 50) {
    text = [`You are well balanced in terms of ${activePersonality.description}`];
  } else {
    // eslint-disable-next-line prefer-destructuring
    text = activePersonality.description[1]; // return the negative side of things
  }
  return text.join(' ').replaceAll(',', ' ');
};
const PersonalityChart = ({ personality, hash }: { personality: ComputedPersonality; hash: string }) => {
  const personalityDimensions: {
    key: string;
    name: string;
    description: string[][];
    color: string;
    className: string;
    activeColor?: string;
  }[] = useMemo(() => {
    return [
      {
        key: 'neuroticism',
        name: 'Neuroticism',
        description: [
          ["You're enthusiastic and dynamic - full of energy.", 'Sometimes you get angry and can worry too much.'],
          ["You're calm and relaxed - 'chilled out'.", "Some people might think you're a bit distant."],
        ],
        color: 'rgb(87, 115, 179)',
        className: 'chart-button--blue',
      },
      {
        key: 'extraversion',
        name: 'Extraversion',
        description: [
          ['You are outgoing and talkative.', 'Some people might think you can be a bit of (sings loudly) an attention seeker.'],
          ["You're happy with your own company and thoughts. Bliss.", 'Some great big loudmouths might describe you as quiet and thoughtful.'],
        ],
        color: '#489982',
        className: 'chart-button--turquoise',
      },
      {
        key: 'intellect_imagination',

        name: 'Openness to experience',
        description: [
          ['You are often curious and interested in new ideas.', 'You prefer things to be different rather than the same allllll the time zzzzzz.'],
          [
            "You prefer things that are tried and tested. That's not boring, right?",
            "You like people who don't give up.",
            "Some people might describe you as 'small minded' - HOW DARE THEY!",
          ],
        ],
        color: '#d3f1f1',
        className: 'chart-button--green',
      },
      {
        key: 'agreeableness',

        name: 'Friendliness',
        description: [
          ['You trust and help other people when you can.'],
          ['Some people might think you are competitive. They are losers.', 'Some people might think you are argumentative. They = wrong. You = right.'],
        ],
        color: '#A36A00',
        className: 'chart-button--orange',
      },
      {
        key: 'conscientiousness',

        name: 'Conscientiousness',
        description: [
          [
            'You are organized and dependable in a very messy world.',
            'Some people would say you are very disciplined and have a sense of duty.',
            "You'd rather know what's coming than for someone to surprise you.",
          ],
          ['People say you are flexible and spontaneous.', 'Some people might wrongly think of you as a bit unreliable. Whatever. What time is it?'],
        ],
        color: '#F8E8B1',
        className: 'chart-button--red',
      },
    ];
  }, []);
  const [activeType, setActiveType] = useState<string>(personalityDimensions[0].key);

  const personalityData = personalityDimensions.map(({ key, color }) => ({
    name: key,
    value: personality[key as keyof ComputedPersonality],
    fill: color,
  }));
  const h = hash.substring(1);
  const personalityDimensionKeys = personalityDimensions.map((personalityDimension) => personalityDimension.key);
  // Make active personality radial bar blink
  const personalityIndex = personalityDimensionKeys.indexOf(h);

  useEffect(() => {
    let nonZero = 0;
    const pathMap = personalityData
      .map((item) => (item.value === 0 ? -1 : nonZero++))
      .reduce<number[]>((carry, item, i) => {
        const reducedCarry = carry;
        if (item !== -1) {
          reducedCarry[item] = i;
        }
        return reducedCarry;
      }, []);
    if (personalityIndex !== -1) {
      document.querySelectorAll('.recharts-radial-bar-sector').forEach((node, i) => {
        node.classList && node.classList.toggle('blinking', pathMap[i] === personalityIndex);
      });
    }
  }, [personalityIndex, personalityData]);

  // Drop all keys, where we are average 40-60

  const buttons = personalityDimensions.map(({ className, key, name }) => {
    return {
      className,
      title: name,
      id: key,
    };
  });

  const activePersonality = personalityDimensions.find((pd) => pd.key === activeType);
  return (
    <div>
      <div>
        <h2 className="text-xl text-bold">Your personality</h2>
        <p>This is how we would describe you</p>
        <div className="personality-results">
          <div className="flex items-center justify-center bg-personalityHeader bg-center bg-no-repeat bg-16	">
            <RadialBarChart
              width={300}
              height={300}
              innerRadius={40}
              outerRadius={110}
              startAngle={90}
              endAngle={-270}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              barSize={10}
              data={personalityData}
            >
              <PolarAngleAxis type="number" domain={[0, 100]} angleAxisId={0} tick={false} />
              <RadialBar
                label={false}
                // minAngle={15}
                background={true}
                // clockWise
                dataKey="value"
                cornerRadius="5"
                onClick={(sector, i) => {
                  const element = document?.querySelector(`.chart-buttons li:nth-child(${i + 1}) a`) as HTMLElement;
                  element?.click();
                }}
              />
            </RadialBarChart>
          </div>

          <ChartButtonContainer buttons={buttons} activeType={activeType} setActiveType={setActiveType} />
        </div>
        <p className="italic  py-2">Click on the dots above to find out more about each of you personality characteristics</p>
        <div>
          {activePersonality && (
            <div className="flex flex-col items-center justify-center">
              <h2 className="text-xl text-bold">{activePersonality.name}</h2>
              <p>{getPersonalityDescription(activePersonality, personality[activeType as keyof ComputedPersonality])}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalityChart;

import { useState, useRef, useEffect, useCallback } from 'react';
import { useSprings } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import { AnswerTimes, AnswersItem, Maybe, QuestionItem } from '__generated__/graphql';
import { useNextStep } from 'hooks/useStep';
import Card from './Card';
import { ProgressStepper } from '../ProgressStepper';

const stackedCardRenderProps = (i: number) => ({
  idx: i,
  x: 0,
  y: 40,
  scale: 1,
  rot: 0,
  delay: i * 100,
});

const timeoutSecs = 6;
let timeoutId: undefined | ReturnType<typeof setTimeout>;

interface DeckProps {
  id: string;
  instructionsActiveInitial: number;
  storeAnswers: (newAnswers: AnswersItem, newAnswerTimes: AnswerTimes) => void;
  questions: Maybe<QuestionItem>[];
}
function Deck(props: DeckProps) {
  const { id, instructionsActiveInitial, storeAnswers, questions } = props;
  const { nextStep } = useNextStep();
  // Answers
  const [answers, setAnswers] = useState<AnswersItem>({});
  const [answerTimes, setAnswerTimes] = useState<AnswerTimes>({});
  const [firstCardShown, setFirstCardShown] = useState<string | null>(null);

  // Book keeping, all indices are card indices
  const [undoHistory, setUndoHistory] = useState<number[]>([]);
  const [redoHistory, setRedoHistory] = useState<number[]>([]);

  // Instructions
  const [instructionsActive, setInstructionsActive] = useState<number | boolean>(instructionsActiveInitial || false);

  // Animation -- disabled, as we're only showing some of the cards in the beginning
  const [animatedProps, setAnimatedProps] = useSprings<{
    idx: number;
    rot: number;
    scale: number;
  }>(questions?.length, (i) => ({
    ...stackedCardRenderProps(i),
    from: stackedCardRenderProps, // outsideViewportCardRenderProps(i)
  }));
  const sapRef = useRef(setAnimatedProps);
  sapRef.current = setAnimatedProps;

  // Overlay animation
  const [springs, setSprings] = useSprings(2, () => ({ opacity: 0 }));

  /**
   * This method will store the answer time for a given question ID.
   * @param questionId the questionId.
   * @param time the ISO timestamp to store. If none is supplied the current time will be converted to an ISO timestamp.
   */
  function storeAnswerTime(questionId: keyof AnswerTimes, isoTimestamp = new Date().toISOString()) {
    const q = answerTimes[questionId] || [];
    setAnswerTimes((at) => ({ ...at, [questionId]: [...q, isoTimestamp] }));
  }

  // stores the answer for question with id questionId
  function storeAnswer(questionId: keyof AnswerTimes, answerValue: boolean) {
    storeAnswerTime(questionId);
    setAnswers((ans) => ({ ...ans, [questionId]: answerValue }));
  }

  function removeCardFromStack(cardIndex: number) {
    // Update undo history
    setUndoHistory((uh) => {
      if (uh.length === 0 || uh[uh.length - 1] !== cardIndex) {
        return [...uh, cardIndex];
      } else {
        return [...uh];
      }
    });

    // Clear redo history
    setRedoHistory([]);
  }

  /** the undo function within the deck component. */
  const undo = useCallback(() => {
    if (undoHistory.length) {
      handleCardTouch();

      // Update undo/redo history
      const lastCardIndex = undoHistory[undoHistory.length - 1];
      setUndoHistory((uh) => uh.slice(0, -1));
      setRedoHistory((rh) => [...rh, lastCardIndex]);

      issueRenderingOfCard(lastCardIndex, {
        ...stackedCardRenderProps(lastCardIndex),
        delay: 0, // do it immediately
      });
    }
  }, [undoHistory]);

  /** the redo function within the deck component. */
  const redo = useCallback(() => {
    if (redoHistory.length) {
      handleCardTouch();

      // Update undo/redo history
      const lastCardIndex = redoHistory[redoHistory.length - 1];
      setUndoHistory((uh) => [...uh, lastCardIndex]);
      setRedoHistory((rh) => rh.slice(0, -1));
      if (lastCardIndex) {
        const questionId = questions[lastCardIndex]?.id;
        if (questionId) {
          const dir = answers[questionId as keyof AnswersItem] ? 1 : -1;
          issueRenderingOfCard(lastCardIndex, {
            x: (200 + window.innerWidth) * dir,
            delay: 0, // do it immediately
          });
        }
      }
    }
  }, [answers, questions, redoHistory]);

  /** This method forces the re-rendering of a card using the provided render properties. */
  function issueRenderingOfCard(
    index: number,
    renderProps: {
      x: number;
      rot?: number;
      scale?: number;
      delay?: number;
      config?: { friction: number; tension: number };
    },
  ) {
    sapRef.current((i) => {
      if (index !== i) return;
      return renderProps;
    });
  }

  // make sure we de-register our timeouts when the component gets unmounted
  useEffect(() => {
    const answerCount = Object.keys(answers).length;
    const lastCardOfDeck = answerCount > 0 && answerCount === questions?.length;
    if (lastCardOfDeck) {
      nextStep();
      // Let asynchronous state updates finish first
      setTimeout(() => {
        storeAnswers(answers, answerTimes);
      }, 200);
    }
    // execute code here that should execute when 'componentDidMount'
    return () => {
      // execute code here that should execute when 'componentWillUnmount'
      if (lastCardOfDeck) {
        clearTimeout(timeoutId);
      }
    };
  }, [answers, questions, answerTimes, storeAnswers, nextStep]); // only execute if answers changed (prevent constant execution)

  const handleCardTouch = () => {
    setInstructionsActive(false);

    // Hide instructions
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setInstructionsActive(true);
    }, timeoutSecs * 1000);
  };

  function handleCardDrag({
    args: [index],
    active,
    movement: [mx],
    direction: [xDir],
    velocity: [vx],
  }: {
    args: number[];
    active: boolean;
    movement: number[];
    direction: number[];
    velocity: number[];
  }) {
    // No card left on stack
    if (index < 0) return;

    // Handle touch
    handleCardTouch();

    // support for speed and location based triggers
    const speedTrigger = vx > 0.2;
    const horizontalPos = mx / (window.innerWidth / 2); // 0 = center, 1 = one of the sides
    const horizontalPosTrigger = Math.abs(horizontalPos) > 0.7; // when the cursor is more than 70% on a side, -> trigger
    const trigger = speedTrigger || horizontalPosTrigger;
    let dir = 0;
    if (speedTrigger) {
      dir = xDir < 0 ? -1 : 1; // look at movement direction
    } else if (horizontalPosTrigger) {
      dir = mx < 0 ? -1 : 1; // if the trigger is based on the position, ignore movement direction
    }
    const answerValue = dir > 0; // acceptance based on direction

    const isGone = !active && trigger;
    if (isGone) {
      // Store answer
      storeAnswer(questions[index]?.id as keyof AnswerTimes, answerValue);

      // Remove card form stack
      removeCardFromStack(index);
    }

    // Render card
    const x = isGone ? (200 + window.innerWidth) * dir : active ? vx : 0;
    const rot = mx / 100 + (isGone ? dir * 10 * vx : 0);
    const scale = isGone ? 0 : 1;
    const tension = active ? 800 : isGone ? 200 : 500;
    const renderProps = {
      x,
      rot,
      scale,
      delay: undefined,
      config: { friction: 50, tension },
    };
    issueRenderingOfCard(index, renderProps);

    setSprings((i) => {
      const sign = i === 1 ? 1 : -1;
      const opacity = isGone ? 0 : Math.min(Math.max(0, (sign * x) / 100), 1);

      return { opacity };
    });
  }
  const to = (i: number) => ({
    x: 0,
    y: i * -10,
    scale: 1,
    rot: -10 + Math.random() * 20,
    delay: i * 100,
  });
  const bind = useDrag(({ args: [index], active, delta: [xDelta], direction: [xDir], velocity: [vx] }) => {
    const trigger = vx > 0.2;

    const dir = xDir < 0 ? -1 : 1;

    if (!active && trigger) undoHistory.push(index);

    setSprings((i) => {
      if (index !== i) return;
      const isGone = undoHistory[index] !== -1;

      const x = isGone ? (200 + window.innerWidth) * dir : active ? xDelta : 0;

      const rot = xDelta / 100 + (isGone ? dir * 10 * vx : 0);

      const scale = active ? 0 : 1;
      return {
        x,
        rot,
        scale,
        delay: undefined,
        config: { friction: 50, tension: active ? 800 : isGone ? 200 : 500 },
      };
    });

    if (!active && undoHistory.length === questions.length) {
      setTimeout(() => {
        setUndoHistory([]);
        setSprings((i) => to(i));
      }, 600);
    }
  });

  const questionCount = questions.length;

  // store the time when the first card was shown to the user
  if (!firstCardShown) {
    const time = new Date().toISOString();
    setFirstCardShown(time);
    storeAnswerTime(id as keyof AnswerTimes); // use the "deck id" as the "questionId, to store teh first answer"
  }

  const onClickButton = (key: string) => {
    let index = 1;
    let direction = 1;
    if (key === 'left') {
      index = 0;
      direction = -1;
    }
    setSprings((i) => ({ opacity: i === index ? 0.8 : 0 }));
    setTimeout(() => {
      handleCardDrag({
        args: [questionCount - undoHistory.length - 1], // current card index
        active: false,
        movement: [10],
        direction: [direction],
        velocity: [0.3],
      });
      setSprings(() => ({ opacity: 0 }));
    }, 250);
  };
  const progressItems = questions
    .map((q) => {
      return answers[q?.id as keyof AnswersItem];
    })
    .reverse();
  return (
    <div className="flex flex-col items-center">
      <div className="grid ">
        {animatedProps.map(({ idx, rot, scale }, index) => (
          <Card
            key={index}
            idx={idx}
            rot={rot}
            scale={scale}
            question={questions[index]}
            bind={bind}
            instructionsActive={instructionsActive}
            handleCardTouch={handleCardTouch}
            springs={springs}
            onClickButton={onClickButton}
          />
        ))}
      </div>
      <ProgressStepper
        backDisabled={undoHistory.length < 0}
        backOnClick={undo}
        progressActive={undoHistory.length}
        progressCount={`${Math.min(questionCount, undoHistory.length + 1)} of ${questionCount}`}
        forwardDisabled={redoHistory.length < 0}
        forwardOnClick={redo}
        items={progressItems}
      />
    </div>
  );
}

export default Deck;

import { useEffect, useState } from 'react';
import { useNextStep } from 'hooks/useStep';
import { LeftOffDialog } from './LeftOffDialog';
import { Carousel } from '../../components/Carousel';
import { useUser } from '../../context/userContext';
import { checkQuestionsAnswered, isAnswerEmpty } from '../../utils';
import Loading from '../../components/Loading';
import { AnswersItem, Maybe, Question } from '../../__generated__/graphql';

/** this method checks whether the the state would suggest that the user left off in a previous session. */
const whereLeftOff = (allQuestionsAnswered: boolean | undefined, questions: Maybe<Question>, answers: Maybe<AnswersItem>) => {
  if (!isAnswerEmpty(answers)) {
    if (allQuestionsAnswered) {
      // All questions answered, go to results
      return './results-intro';
    }
    if (!checkQuestionsAnswered(questions?.customer, answers)) {
      return '/questions/customer';
    }
    if (!checkQuestionsAnswered(questions?.values, answers)) {
      return '/questions/values';
    }
    if (!checkQuestionsAnswered(questions?.interests, answers)) {
      return '/questions/interests';
    }
    if (!checkQuestionsAnswered(questions?.personality, answers)) {
      return '/questions/personality';
    }
  }
};

const AppInstructions = () => {
  const { nextStep } = useNextStep();
  const carousel: { image: string; caption?: string }[] = [
    {
      image: '/companyLogo.png',

      caption:
        // eslint-disable-next-line max-len
        'Welcome to TeamFit, a simple values and personality survey. It only takes 5 minutes and can be completed on your phone or a computer.',
    },
    {
      image: '../img/swipe.png',
      caption: 'Thumbs up or thumbs down to answer simple questions. Remember, there are no wrong or right answers!',
    },
    {
      image: '/high-five.png',

      caption:
        'The results will help us get to know you better. We will send you the results too, so you can understand and promote your strengths when applying for roles.',
    },
  ];
  const { allQuestionsAnswered, questions, answers, userLoading } = useUser();
  const [leftOffUrl, setLeftOffUrl] = useState<string>();

  useEffect(() => {
    const defaultLeftOffUrl = whereLeftOff(allQuestionsAnswered, questions, answers);

    if (defaultLeftOffUrl) {
      setLeftOffUrl(defaultLeftOffUrl);
    }
  }, [allQuestionsAnswered, answers, questions]);
  if (userLoading) return <Loading />;

  return (
    // add fade in animation
    <div className="flex flex-col items-center justify-around text-center text-white		 h-5/6	 pt-6">
      <div className="ps-3">
        <div className="text-white text-xl text-center sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">TeamFit</div>
      </div>
      {leftOffUrl && <LeftOffDialog key={1} setLeftOffUrl={setLeftOffUrl} leftOffUrl={leftOffUrl} />}
      <div className="z-10 mb-2">
        <Carousel items={carousel} />
      </div>
      <button type="button" className="bg-yellow-500 text-black 	p-2	uppercase rounded-lg font-semibold text-md " onClick={() => nextStep()}>
        Get started
      </button>
    </div>
  );
};

export { AppInstructions };

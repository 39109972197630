import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';
import { useCompanyParams } from 'hooks/useCompanyParams';
import Header from './Header';
import NoCompany from './NotCompany';

function Layout() {
  const company = useCompanyParams();
  return (
    <div className="h-dvh w-screen		bg-primary-600">
      <Suspense fallback={null}>
        <Header />
        <div className="my-10 px-4 md:px-[5%]">{company ? <Outlet /> : <NoCompany />}</div>
      </Suspense>
      <footer>
        <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8 fix bottom-0 left-0 right-0">
          <span className="block text-md text-white sm:text-center dark:text-gray-400">© 2024 Teamfit. All Rights Reserved.</span>
        </div>
      </footer>
    </div>
  );
}

export default Layout;

import React from 'react';
// import "../styles/Instructions.css";
import { useNextStep } from 'hooks/useStep';

const SectionBreak = ({ title, body, icon }: { title: string; body: string[]; icon?: string }) => {
  const { nextStep } = useNextStep();
  return (
    <div className="h-5/6	flex flex-col items-center justify-between  text-center text-white gap-20">
      <div className="text-white text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1 mt-5">{title}</div>
      <img className="w-16 h-16 text-white" alt="section-break-icon" src={icon} />
      {body.map((p, i) => (
        <p className="text-white" key={`p${i}`}>
          {p.replace('%COMPANY%', 'BelleVie')}
        </p>
      ))}

      <button className="bg-yellow-500 text-black 	p-2	uppercase rounded-lg font-semibold text-md " type="button" onClick={() => nextStep()}>
        Continue
      </button>
    </div>
  );
};

export default SectionBreak;

import { useState, useEffect, useMemo } from 'react';
import { RadialBarChart, RadialBar, PolarAngleAxis } from 'recharts';
import '../../styles/Charts.css';
import ChartButtonContainer from '../../components/ChartButtonContainer';
import { ComputedVocationalType } from './utils';

const InterestsChart = ({ vocationalTypes, hash }: { vocationalTypes: ComputedVocationalType; hash: string }) => {
  const vocationalInterests = useMemo(() => {
    return [
      {
        key: 'realistic',
        name: 'Practical',
        description: [
          "You're hands on - not happy at a desk.",
          "You'd enjoy working with plants and animals or real-world materials like wood, tools, and machinery.",
          'Working outside is quite likely to make you really happy.',
        ],
      },
      {
        key: 'investigative',
        name: 'Problem Solving',
        description: [
          'You enjoy work that has to do with ideas and thinking rather than physical activity or leading people.',
          'To solve problems at work you will search for facts and figure out how to make things better.',
        ],
      },
      {
        key: 'artistic',

        name: 'Artistic',
        description: [
          "Deep down you're an artist! You enjoy work that helps you express yourself such as acting, music, art, and design.",
          'They like creativity in their work and work that can be done without following a set of rules.',
        ],
      },
      {
        key: 'social',

        name: 'Social',
        description: [
          'You like working with others to help them learn and grow.',
          'You enjoy working with people more than working with objects, machines, or information.',
          'You also enjoy teaching, giving advice helping and being of service to people.',
        ],
      },
      {
        key: 'enterprising',

        name: 'Enterprising',
        description: [
          'You like work with a bit of risk and lots of reward where you can be in control some of the time.',
          'You take action quickly rather than think about things for too long.',
          'You influence people positively and will make a good leader.',
        ],
      },
      {
        key: 'conventional',

        name: 'Conventional',
        description: [
          'You like work with a bit of risk and lots of reward where you can be in control some of the time.',
          'You take action quickly rather than think about things for too long.',
          'You influence people positively and will make a good leader.',
        ],
      },
    ];
  }, []);

  const topType = Object.keys(vocationalTypes).reduce((acc, curr) =>
    vocationalTypes[acc as keyof ComputedVocationalType] > vocationalTypes[curr as keyof ComputedVocationalType] ? acc : curr,
  );
  const secondType = Object.keys(vocationalTypes)
    .filter((t) => t !== topType)
    .reduce((acc, curr) => (vocationalTypes[acc as keyof ComputedVocationalType] > vocationalTypes[curr as keyof ComputedVocationalType] ? acc : curr));

  const [topInterest] = vocationalInterests.filter((vocationalInterest) => vocationalInterest.key === topType);
  const [secondInterest] = vocationalInterests.filter((vocationalInterest) => vocationalInterest.key === secondType);
  const [activeType, setActiveType] = useState('top-interest');

  const interestData = [
    {
      name: topType,
      value: vocationalTypes[topType as keyof ComputedVocationalType],
      fill: '#E8C46A',
    },
    {
      name: secondType,
      value: vocationalTypes[secondType as keyof ComputedVocationalType],
      fill: '#FFFFFF',
    },
  ];

  useEffect(() => {
    const h = hash.substring(1);
    // Make vocational type radial bar blink
    const interestIndex = ['top-interest', 'second-interest'].indexOf(h);
    if (interestIndex !== -1) {
      document.querySelectorAll('#work-interests-results .recharts-radial-bar-sector').forEach((node, i) => {
        node.classList && node.classList.toggle('animate-blinking', i === interestIndex);
      });
    }
  }, [hash]);

  return (
    <div>
      <div>
        <h2 className="text-xl text-bold">Your work interests</h2>
        <p>This is what you'd look for in a career</p>

        <div>
          <div className="flex items-center justify-center bg-star bg-center bg-no-repeat bg-16	">
            <RadialBarChart
              width={220}
              height={220}
              innerRadius={70}
              outerRadius={110}
              startAngle={90}
              endAngle={-270}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              barSize={10}
              data={interestData}
            >
              <PolarAngleAxis type="number" domain={[0, 100]} angleAxisId={0} tick={false} />
              <RadialBar
                label={false}
                // minAngle={15}
                background={true}
                // clockWise
                dataKey="value"
                cornerRadius="5"
                onClick={(sector, i) => {
                  const element = document.querySelector(`.interest-chart.chart-buttons li:nth-child(${i + 1}) a`) as HTMLElement;
                  element?.click();
                }}
              />
            </RadialBarChart>
          </div>

          <ChartButtonContainer
            buttons={[
              {
                className: 'chart-button--yellow',
                title: 'Top interest',
                id: 'top-interest',
              },
              {
                className: 'chart-button--white',
                title: 'Second interest',
                id: 'second-interest',
              },
            ]}
            activeType={activeType}
            setActiveType={setActiveType}
          />
        </div>
        <p className="italic py-2">Click on the dots above to find out more about each of you interest characteristics</p>

        <div className="text-center">
          {activeType === 'top-interest' ? (
            <div className="flex flex-col items-center justify-center">
              <p className="text-bold">{`${vocationalTypes[topType as keyof ComputedVocationalType]} %`}</p>
              <h2 className="text-xl text-bold">{topInterest.name}</h2>
              <p>{topInterest.description.join(' ')}</p>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center">
              <p className="text-bold">{`${vocationalTypes[secondType as keyof ComputedVocationalType]} %`}</p>
              <h2 className="text-xl text-bold">{secondInterest.name}</h2>
              <p>{secondInterest.description}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InterestsChart;
